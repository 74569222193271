$base-spacing: 16px;
$base-spacing-half: $base-spacing * 0.5;

$base-font-size: 13px;
$base-line-height: 20px;

$base-font-size-table: 12px;
$base-font-size-table-header: 11px;

$color-thunder-blue: #007ac1;
//$color-thunder-navy: #0050af;
$color-thunder-navy: #4285F4;
$color-thunder-navy-light: #ccdcef;
$color-thunder-navy-500: $color-thunder-navy;
$color-thunder-navy-dark: #002D62;
$color-thunder-navy-darker: #002A4E;

$color-link: $color-thunder-navy-dark;

$color-sunset: #f2624f;
$color-sunset-light: #fbd7d3;
$color-sunset-500: #f2624f;
$color-sunset-dark: #ef3b24;

.md-padding-half {
  padding: $base-spacing * 0.5;
}

.md-padding {
  padding: $base-spacing;
}

.md-padding-2x {
  padding: 2 * $base-spacing;
}

.md-margin {
  margin: $base-spacing;
}

.md-margin-half {
  margin: $base-spacing * 0.5;
}

// Other Colors
$on-white-hover: #f8f8f8; // color to use when you're hovering an element that's background is white
$greysmoke: #ebebeb;
$lightgreysmoke: #f5f5f5; // = to black with 0.04 opacity
$on-greysmoke-hover: darken($greysmoke, 5%);
$almostblack: #212121;
$almostwhite: #f8f8f8;
$grey: #757575; // 54% black, collapsed and secondary text, active icon color
$lightergrey: #b3b3b3; // 30% black, even lighter grey text?
$black: rgba(0, 0, 0, 0.87);
$black-muted: rgba(0, 0, 0, 0.54);
$link-color: #4285F4;

$text-color-on-dark: $greysmoke;
$color-bg-fill: $greysmoke;
$color-comment-fill: #f8f9fa;
$color-secondary-nav-bar-fill: #f5f5f5;
$color-fab-fill: $color-secondary-nav-bar-fill;
$color-post-fill: #fff;

$app-toolbar-background: #f8f9fa;
$sidebar-card-background: #f8f9fa;

$dark-header: $almostblack;
$form-separator-color: #ccc;

$ok: #0f9d58;
$warning: #ffbf05;
$error: $color-sunset-dark;

$percentile-min: #bf1e0a;
$percentile-max: #36825D;

$blue-red-percentile-min: $color-thunder-navy;
$blue-red-percentile-mid: $warning;
$blue-red-percentile-max: $color-sunset-dark;
$blue-red-percentile-q1-max: mix($blue-red-percentile-min, #fff, 50);
$blue-red-percentile-q3-max: mix($blue-red-percentile-max, #fff, 50);

$yellow-purple-percent-min: $warning;
$yellow-purple-percent-max: mix($blue-red-percentile-min, $blue-red-percentile-max);

@mixin label-error() {
  color: $error;
}

$percent-L01: mix($yellow-purple-percent-max, $lightgreysmoke, 100%);
$percent-L02: mix($yellow-purple-percent-max, $lightgreysmoke, 80%);
$percent-L03: mix($yellow-purple-percent-max, $lightgreysmoke, 60%);
$percent-L04: mix($yellow-purple-percent-max, $lightgreysmoke, 40%);
$percent-L05: mix($yellow-purple-percent-max, $lightgreysmoke, 20%);
$percent-L06: mix($lightgreysmoke, $yellow-purple-percent-min, 100%);
$percent-L07: mix($lightgreysmoke, $yellow-purple-percent-min, 80%);
$percent-L08: mix($lightgreysmoke, $yellow-purple-percent-min, 60%);
$percent-L09: mix($lightgreysmoke, $yellow-purple-percent-min, 40%);
$percent-L10: mix($lightgreysmoke, $yellow-purple-percent-min, 20%);
$percent-L11: mix($lightgreysmoke, $yellow-purple-percent-min, 0%);

$mat-divider-color: rgba(0, 0, 0, 0.12);

// Draft app
// =====================
// Colors
// =====================
// Dark mode
$color-dm-background: #121212;  // No mockup value, but we are contrasting this + lighter black (see below) for mat cards/tables on desktop
$color-dm-mat-card-background: #232323;
$color-dm-mat-table-background: #232323;
$color-dm-filter-chips: #4285f4;
$color-dm-filter-chips-border: rgba(66, 133, 244, 0.3);
$color-dm-kiosk-background: #232323;
$color-dm-kiosk-heading: rgba(255, 255, 255, 0.7);
$color-dm-kiosk-magnet-background: rgba(255, 255, 255, 0.1);
$color-dm-kiosk-magnet-text: rgba(255, 255, 255, 0.7);
$color-dm-kiosk-magnet-thunder-rank: #3d8af7;
$color-dm-kiosk-team-picks: #00c8f8;

// Kiosk
$color-kiosk-background: #ebebeb;
$color-kiosk-heading: #606060;
$color-kiosk-non-thunder-rank: #444444;  // Flow-chart black
$color-kiosk-magnet-background: white;
$color-kiosk-magnet-border: #aaaaaa;  // Flow-chart lightgray
$color-kiosk-magnet-player-info: #7a7a7a;
$color-kiosk-magnet-thunder-rank: #0844a4;

// Shared across all components
$color-mat-table-sticky-border: #e0e0e0;
$color-mat-table-sort-border: $lightergrey;

// Draft order
$color-team-picks: #3d8af7;
$color-selected-checkmark: #72bb53;

// Flow chart
$color-highlighted: #BBDEFB;

// Preference lists
$color-interest-yes: lightgreen;
$color-interest-no: lightcoral;
$color-interest-undecided: #ffffb9;  // Data Management rgba(255, 255, 128, .5)
$color-interest-border: grey;

// League Rosters
$color-kiosk-league-background: #121212;

// =====================
// Element defaults
// =====================
$kiosk-outer-side-margin: 18;
$kiosk-outer-bottom-margin: 6;
$kiosk-heading-font-size: 16;
$kiosk-magnet-expanded-height: 44;
$kiosk-magnet-height: 28.5;
$kiosk-magnet-font-size: 19;
$kiosk-magnet-font-size-smaller: 14;
$kiosk-magnet-font-size-smallest: 10;
$kiosk-magnet-details-font-size: 12;
$kiosk-magnet-margin-bottom: 4;
$kiosk-magnet-border-width: 0.5;
$kiosk-magnet-border-radius: 5;
$kiosk-magnet-inner-margin: 4;
$kiosk-magnet-inner-margin-larger: 8;
$kiosk-chip-font-size: 14;
$kiosk-chip-height: 28.5;

$kiosk-breakpoint-quotient-unit-values: (
  ('max-aspect-ratio:4/3', 16, 'vw', 16),
  ('min-aspect-ratio:4/3', 10.1, 'vh', 10.5),
);

// Covalent replacements
$layout-breakpoint-sm: 960px !default;
$layout-breakpoint-md: 1280px !default;
$layout-breakpoint-lg: 1920px !default;
