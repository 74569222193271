@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$md-bild-primary: (
    50 : #e0eaf5,
    100 : #b3cbe7,
    200 : #80a8d7,
    300 : #4d85c7,
    400 : #266abb,
    500 : #4285F4,
    600 : #0049a8,
    700 : #00409f,
    800 : #003796,
    900 : #002786,
    A100 : #b3c2ff,
    A200 : #809aff,
    A400 : #4d72ff,
    A700 : #335dff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-bild-primary-accent: (
    50 : #fdecea,
    100 : #fbd0ca,
    200 : #f9b1a7,
    300 : #f69184,
    400 : #f47a69,
    500 : #f2624f,
    600 : #f05a48,
    700 : #ee503f,
    800 : #ec4636,
    900 : #e83426,
    A100 : #ffffff,
    A200 : #fff1f1,
    A400 : #ffc2be,
    A700 : #ffaaa4,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-bild-dark: (
    50 : #e0e6ec,
    100 : #b3c0d0,
    200 : #8096b1,
    300 : #4d6c91,
    400 : #264d7a,
    500 : #002d62,
    600 : #00285a,
    700 : #002250,
    800 : #001c46,
    900 : #001134,
    A100 : #6c88ff,
    A200 : #395eff,
    A400 : #0635ff,
    A700 : #002deb,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-bild-dark-accent: (
    50 : #fefefe,
    100 : #fcfcfc,
    200 : #fafafa,
    300 : #f8f8f8,
    400 : #f7f7f7,
    500 : #f5f5f5,
    600 : #f4f4f4,
    700 : #f2f2f2,
    800 : #f0f0f0,
    900 : #eeeeee,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$bild-app-primary: mat.define-palette($md-bild-primary);
$bild-app-primary-accent:  mat.define-palette($md-bild-primary-accent);

$bild-app-dark: mat.define-palette($md-bild-dark);
$bild-app-dark-accent: mat.define-palette($md-bild-dark-accent);

// Create the theme object (a Sass map containing all of the palettes).
$bild-app-primary-theme: mat.define-light-theme($bild-app-primary, $bild-app-primary-accent);
$bild-app-dark-theme: mat.define-light-theme($bild-app-dark, $bild-app-dark-accent);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($bild-app-primary-theme);

// Button theme, use primary theme for primary colors (buttons), dark accent colors for accent theme (FABs)
// This will give us normal Thunder navy buttons for raised buttons when specified with primary, and light grey FABs
$bild-button-theme: mat.define-light-theme($bild-app-primary, $bild-app-dark-accent);
@include mat.legacy-button-theme($bild-button-theme);
@include mat.toolbar-theme($bild-app-dark-theme);

// Draft dark theme
$draft-dark-theme-primary: mat.define-palette($md-bild-dark);
$draft-dark-theme-accent: mat.define-palette($md-bild-dark-accent);
$draft-dark-theme-warn: mat.define-palette(mat.$deep-orange-palette);

$draft-dark-theme: mat.define-dark-theme(
 $draft-dark-theme-primary,
 $draft-dark-theme-accent,
 $draft-dark-theme-warn
);

@import 'vars.scss';

// Bild Material
$bild-tooltip-target-height: 22px;
$bild-tooltip-font-size: 10px;
$bild-tooltip-vertical-padding: ($bild-tooltip-target-height - $bild-tooltip-font-size) * 0.5;

$bild-tooltip-handset-target-height: 30px;
$bild-tooltip-handset-font-size: 14px;
$bild-tooltip-handset-vertical-padding:
  ($bild-tooltip-handset-target-height - $bild-tooltip-handset-font-size) * 0.5;

.bild-tooltip {
  background: mat.get-color-from-palette(mat.$grey-palette, 700, 0.9);
}

.bild-tooltip {
  font-family: mat.font-family($bild-app-primary-theme);
  font-size: $bild-tooltip-font-size;
  padding-top: $bild-tooltip-vertical-padding;
  padding-bottom: $bild-tooltip-vertical-padding;
}

.bild-tooltip-handset {
  font-size: $bild-tooltip-handset-font-size;
  padding-top: $bild-tooltip-handset-vertical-padding;
  padding-bottom: $bild-tooltip-handset-vertical-padding;
}
