@import 'vars';
@import 'media';

.columns {
  &.two {
    grid-template-columns: 240px minmax(280px, 1fr);
    grid-column-gap: 12px;
    background-color: #f6f8fc;

    @media #{$lt-md} {
      grid-template-columns: 1fr;
    }
  }
  &.three {
    grid-template-columns: 240px minmax(600px, 1000px) minmax(400px, 1fr);
    grid-column-gap: 12px;
    background-color: #f6f8fc;

    @media #{$lt-lg} {
      grid-template-columns: 240px minmax(400px, 1fr) 264px;
    }
    @media #{$lt-md} {
      grid-template-columns: 1fr;
    }
  }

  display: grid;
  grid-template-rows: 1fr;
  width: 100%;
  height: initial;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .left-shoulder {
    background-color: white;
    max-height: 100vh;
    height: 100%;
    position: absolute;

    grid-column-start: 1;
    grid-column-end: span 1;

    display: flex;
    flex-direction: column;

    h1 {
      display: flex;
      place-content: center flex-start;
      align-items: center;
      background-color: white;
      font-weight: 100;
      height: 48px;
      margin: 0;
      padding-left: 0;

      a {
        color: $almostblack;
        text-decoration: none;
      }
    }

    padding-left: 0;

    @media #{$lt-md} {
      display: none;
    }
  }

  .middle {
    background-color: white;
    grid-column-start: 2;
    grid-column-end: span 1;

    padding: $base-spacing * 0.5 $base-spacing * 1.25;

    @media #{$lt-md} {
      padding: $base-spacing * 0.5;
      grid-column-start: 1;
      grid-column-end: span 1;
      grid-row-start: 1;
      grid-row-end: span 1;
      overflow-x: auto;
    }
  }

  .right-shoulder-backdrop {
    @media #{$right-shoulder-breakpoint} {
      // We want the right shoulder to occupy all extra available space on larger displays:
      // 240px is the width of the left panel, 24px is the grid-gap, 1000px is the middle container
      width: calc(100% - 240px - 24px - 1000px);
    }
    @media #{$lt-lg} {
      width: 264px;
    }
    @media #{$gt-md} {
      min-width: 400px;
    }
    z-index: 0;
    position: absolute;
    right: 0;
    margin-top: 0 !important;
    background: white;
    height: 100vh !important;
    max-height: 100% !important;
    padding-top: 16px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .right-shoulder {
    // Provides us the proper margin so that we line up with the top of the middle column
    margin-top: 64px;

    @media #{$right-shoulder-breakpoint} {
      // We want the right shoulder to occupy all extra available space on larger displays:
      // 240px is the width of the left panel, 24px is the grid-gap, 1000px is the middle container
      width: calc(100% - 240px - 24px - 1000px);
    }

    @media #{$lt-lg} {
      max-width: 260px;
    }
    @media #{$gt-md} {
      min-width: 400px;
    }

    position: sticky;
    min-height: 1%;
    height: 1%;

    grid-column-start: 3;
    grid-column-end: span 1;
    padding: $base-spacing * 0.5 $base-spacing * 2 $base-spacing * 0.5 $base-spacing + 8px;

    .mat-nav-list {
      .mat-list-item {
        border-left: 2px solid transparent;
        color: #6b6b6b;
        font-size: 14px;
        height: 36px;

        &:hover {
          border-left: 2px solid #002d62;
          color: #6b6b6b;
          font-weight: 400;
        }

        .mat-list-item-content {
          height: 36px;
        }
      }
    }

    @media #{$lt-md} {
      display: none;
    }
  }
}


.mat-bottom-sheet-container.nav-menu {
  padding: 8px 0;
}
